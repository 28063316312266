import aug from './aug.gif';
import logo from './flamingLogo.gif';
import guy from './csgoGuy.gif';
import './App.css';
import React, { useState, useEffect, resize } from 'react'

function App() {

  return (
    <div style={{
      display: "flex",
      alignItems: "center", 
      flexDirection: "column",
      width: window.innerWidth,
      height: window.innerHeight,
      backgroundColor: "#212020",
      color: "#212020",
      backgroundImage: "url(" + guy + ")",
      }}>
      <img src={logo} style={{width: "70%"}}></img>
      <img src={aug} style={{width: "50%"}}></img>
      {/* <img src={guy} style={{width: "90%"}}></img> */}
    </div>
  );
}

export default App;
